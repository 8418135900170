






import Vue from 'vue'
import ExchangeService from '@/services/exchange'
import { cloneDeep as _cloneDeep } from 'lodash'
import moment from 'moment'

export default Vue.extend({
  props: {
    refresh: {
      type: Boolean,
      required: true
    }
  },

  data(): {
    lineChartOptions: Record<string, any>
    loading: boolean
  } {
    return {
      lineChartOptions: {
        chart: {
          type: 'spline',
          height: '150px',
          backgroundColor: '#1f2324'
        },
        legend: {
          enabled: false
        },
        title: {
          text: ''
        },
        yAxis: [
          {
            // left y axis
            title: {
              text: ''
            }
          },
          {
            // right y axis
            opposite: true,
            title: {
              text: ''
            }
          }
        ],
        xAxis: {
          categories: []
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<div class="tooltip-header">{point.key}</div>',
          pointFormat: '<div style="color: {series.color};">{series.name}: {point.y}</div>',
          footerFormat: '</div>',
          valueDecimals: 2
        },
        plotOptions: {
          series: {
            cursor: 'pointer'
          }
        },
        series: [
          {
            name: 'USD',
            data: [0],
            color: ''
          },
          {
            name: 'EUR',
            data: [0],
            color: ''
          }
        ],
        credits: {
          enabled: false
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 550
              },
              chartOptions: {
                chart: {
                  spacingLeft: 3,
                  spacingRight: 3
                },
                legend: {
                  itemWidth: 100
                }
              }
            }
          ]
        }
      },

      loading: false
    }
  },

  methods: {
    async getBalanceLineChart() {
      this.loading = true

      try {
        const fromDate = moment('2019-01-01').valueOf()
        const toDate = moment().add(1, 'day').utc().valueOf()
        const { data: dataRes } = await ExchangeService.getBalanceLineChart({ fromDate, toDate })
        if (dataRes.data.length === 0) return

        const categories: string[] = []
        const series: any = [
          {
            name: dataRes.data[0].balances[0].currency,
            data: [],
            color: 'var(--color-success)'
          },
          {
            name: dataRes.data[0].balances[1].currency,
            data: [],
            color: 'var(--color-orange)'
          }
        ]

        const yAxis = [
          {
            // left y axis
            title: {
              text: dataRes.data[0].balances[0].currency
            }
          },
          {
            // right y axis
            opposite: true,
            title: {
              text: dataRes.data[0].balances[1].currency
            }
          }
        ]

        dataRes.data.forEach((item) => {
          categories.push(item.date)
          series[0].data.push(item.balances[0].total)
          series[1].data.push(item.balances[1].total)
        })

        this.lineChartOptions = {
          ..._cloneDeep(this.lineChartOptions),
          xAxis: { categories },
          series,
          yAxis
        }
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    this.getBalanceLineChart()
  },

  watch: {
    refresh() {
      this.getBalanceLineChart()
    }
  }
})
